// @flow
import { style } from 'typestyle';
import Popup from '@components/popups/DynamicBase';

import * as QualityContent from '@components/popups/content/WhyChooseLumera__Quality';
import * as ReturnsContent from '@components/popups/content/WhyChooseLumera__Returns';
import * as SecurityContent from '@components/popups/content/WhyChooseLumera__Security';
import * as SelectionContent from '@components/popups/content/WhyChooseLumera__Selection';
import * as TradeInContent from '@components/popups/content/WhyChooseLumera__TradeIn';
import * as ValueContent from '@components/popups/content/WhyChooseLumera__Value';

type StateType = {
  show: boolean,
  popupType: string | null
};

const links = {};

const content = {
  selection: SelectionContent,
  value: ValueContent,
  'trade-in': TradeInContent,
  quality: QualityContent,
  returns: ReturnsContent,
  security: SecurityContent
};

export default class WhyChooseLumeraPopup extends React.Component<
  {},
  StateType
> {
  closeTimerId: TimeoutID;

  state = {
    show: false,
    popupType: null
  };

  mouseoutHandlers = {
    selection: () => this.mouseOut('selection'),
    value: () => this.mouseOut('value'),
    'trade-in': () => this.mouseOut('trade-in'),
    quality: () => this.mouseOut('quality'),
    returns: () => this.mouseOut('returns'),
    security: () => this.mouseOut('security')
  };

  blurHandlers = {
    selection: () => this.mouseOut('selection'),
    value: () => this.mouseOut('value'),
    'trade-in': () => this.mouseOut('trade-in'),
    quality: () => this.mouseOut('quality'),
    returns: () => this.mouseOut('returns'),
    security: () => this.mouseOut('security')
  };

  componentDidMount() {
    var selection = Rails.$('a[data-popup="why--selection"]')[0];
    var value = Rails.$('a[data-popup="why--value"]')[0];
    var tradeIn = Rails.$('a[data-popup="why--trade-in"]')[0];
    var quality = Rails.$('a[data-popup="why--quality"]')[0];
    var returns = Rails.$('a[data-popup="why--returns"]')[0];
    var security = Rails.$('a[data-popup="why--security"]')[0];

    links['selection'] = selection;
    links['value'] = value;
    links['trade-in'] = tradeIn;
    links['quality'] = quality;
    links['returns'] = returns;
    links['security'] = security;

    selection.addEventListener('focus', this.showPopup);
    selection.addEventListener('mouseover', this.showPopup);
    selection.addEventListener('mouseout', this.mouseoutHandlers.selection);
    selection.addEventListener('blur', this.mouseoutHandlers.selection);

    value.addEventListener('focus', this.showPopup);
    value.addEventListener('mouseover', this.showPopup);
    value.addEventListener('mouseout', this.mouseoutHandlers.value);
    value.addEventListener('blur', this.mouseoutHandlers.value);

    tradeIn.addEventListener('focus', this.showPopup);
    tradeIn.addEventListener('mouseover', this.showPopup);
    tradeIn.addEventListener('mouseout', this.mouseoutHandlers['trade-in']);
    tradeIn.addEventListener('blur', this.mouseoutHandlers['trade-in']);

    quality.addEventListener('focus', this.showPopup);
    quality.addEventListener('mouseover', this.showPopup);
    quality.addEventListener('mouseout', this.mouseoutHandlers.quality);
    quality.addEventListener('blur', this.mouseoutHandlers.quality);

    returns.addEventListener('focus', this.showPopup);
    returns.addEventListener('mouseover', this.showPopup);
    returns.addEventListener('mouseout', this.mouseoutHandlers.returns);
    returns.addEventListener('blur', this.mouseoutHandlers.returns);

    security.addEventListener('focus', this.showPopup);
    security.addEventListener('mouseover', this.showPopup);
    security.addEventListener('mouseout', this.mouseoutHandlers.security);
    security.addEventListener('blur', this.mouseoutHandlers.security);
  }

  componentWillUnmount() {
    [
      'selection',
      'value',
      'trade-in',
      'quality',
      'returns',
      'security'
    ].forEach(type => {
      const el = links[type];

      if (!el) return;

      el.removeEventListener('focus', this.showPopup);
      el.removeEventListener('mouseover', this.showPopup);
      el.removeEventListener('mouseout', this.mouseoutHandlers[type]);
      el.removeEventListener('blur', this.mouseoutHandlers[type]);
    });
  }

  showPopup = (e: SyntheticEvent<HTMLElement>) => {
    const linkType = e.currentTarget.dataset['popup'].replace('why--', '');
    if (window) {
      window.clearTimeout(this.closeTimerId);
    }

    this.setState(
      {
        show: false,
        popupType: undefined
      },
      () => {
        this.setState({
          show: true,
          popupType: linkType
        });
      }
    );
  };

  mouseOverPopup = () => {
    if (window) {
      window.clearTimeout(this.closeTimerId);
    }
  };

  mouseOut = (closeOnlyType: string | null) => {
    const self = this;

    (function(type) {
      self.closeTimerId = setTimeout(function() {
        self.setState(prevState => {
          if (prevState.popupType === type) {
            let newState = {
              show: false,
              popupType: undefined
            };

            return newState;
          } else {
            return prevState;
          }
        });
      }, 150);
    })(closeOnlyType);
  };

  closePopup = () => {
    this.setState({
      show: false,
      popupType: null
    });
  };

  render() {
    return (
      <div>
        {this.state.popupType ? (
          <Popup
            show={this.state.show}
            anchorElement={
              this.state.popupType ? links[this.state.popupType] : undefined
            }
            onMouseOver={this.mouseOverPopup}
            onMouseOut={() => this.mouseOut(this.state.popupType)}
            hideCloseButton
            doNotScrollToPopup
            preferSideAttachment
            initialContent={content[this.state.popupType]}
          />
        ) : null}
      </div>
    );
  }
}
